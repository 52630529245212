import * as React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"


import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Contact" />
      <div className="contacts">
        <form className="" action="https://formspree.io/farshadj101@gmail.com" method="POST" >
            <div className="field">
                <div className="control">
                    <input className="input" pattern="[A-Z,a-z, ]*" type="text" id="Name" name="name" placeholder="Name" />
                </div>
            </div>

            <br />
            <div className="field">
                <div className="control">
                    <input className="input" type="text" id="Email" name="email" placeholder="Email" />
                </div>
            </div>
            <br />
            <div className="field">
                <div className="control">
                    <textarea className="textarea" type="text" rows="5" id="note" name="message" placeholder="Message"></textarea>
                </div>
            </div>
            <p>
            <button className="button is-primary" type="submit">
                Submit
            </button>
            </p>
        </form>
    </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
